<template>
  <purchase-logs-card />
</template>
<script>
import PurchaseLogsCard from "./components/PurchaseLogsCard.vue";
export default {
  components: {
    PurchaseLogsCard,
  },
};
</script>
