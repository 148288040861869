
<template>
  <div>
    <v-toolbar flat>
      <v-toolbar-title>Purchase Logs Aggreate</v-toolbar-title>
      <v-divider class="mx-2" inset vertical></v-divider>
      <v-text-field v-model="search" label="UserId" single-line v-on:keyup.enter="initialize" hide-details></v-text-field>
      <v-divider class="mx-2" inset vertical></v-divider>

      <v-combobox style="width: 200px;" v-model="productTypeSelect" :items="productTypeItems" item-value="id" item-text="value" 
        hide-details  @change="initialize" label="Product Type"></v-combobox>
        <v-divider class="mx-4" inset vertical></v-divider>

      <v-menu ref="menu" v-model="menu" :close-on-content-click="false" :return-value.sync="dates"
        transition="scale-transition" offset-y min-width="auto">
        <template v-slot:activator="{ on, attrs }">
          <v-text-field v-model="dates" label="Picker in menu" prepend-icon="mdi-calendar" readonly single-line
            hide-details v-bind="attrs" v-on="on"></v-text-field>
        </template>
        <v-date-picker v-model="dates" locale="tr-TR" range no-title scrollable>
          <v-spacer></v-spacer>
          <v-btn text color="primary" @click="menu = false">
            Cancel
          </v-btn>
          <v-btn text color="primary" @click="initialize">
            OK
          </v-btn>
        </v-date-picker>
      </v-menu>

      <v-divider class="mx-2" inset vertical></v-divider>
      <v-combobox v-model="pageItemSelect" :items="pageItems" item-value="id" item-text="value" @change="initialize"
        hide-details label="Page"></v-combobox>
        <v-divider class="mx-4" inset vertical></v-divider>


      <v-btn color="primary" dark @click="initialize" class="mb-2 ma-2">Search</v-btn>
    </v-toolbar>
    
  
    <v-data-table :headers="purchaseAggregationLogsDataTable"  :items="$store.state.purchaseLogs.aggregation"
      :items-per-page="pageItemSelect.id" hide-default-footer :loading="loading" :options.sync="options"
      loading-text="Loading... Please wait" class="elevation-1">
      <template v-slot:item.tg="{ item }">
        <div>{{ checkItemForZeroOrNull(item.tg)|formatMoney}}</div>
      </template>
      <template v-slot:item.tc="{ item }">
        <div>{{ checkItemForZeroOrNull(item.tc)|formatMoney}}</div>
      </template>
    </v-data-table>
    <br>
    <hr style="height: 5px; background-color: orange;">
    <br>

    <v-data-table :headers="purchaseLogsDataTable"  :items="$store.state.purchaseLogs.data"
      :items-per-page="pageItemSelect.id" hide-default-footer :loading="loading" :options.sync="options"
      loading-text="Loading... Please wait" class="elevation-1">

      <template v-slot:item.u="{item}">
        <UserDetailCard
          :userId="item.u.i"
          :pictureUrl="item.u.p"
          :userName="item.u.n"
          :level="item.u.l"
        />
      </template>

      <template v-slot:item.pd="{ item }">
        <div>{{ unixTimestampToFormattedDate(item.pd)}}</div>
      </template>

      <template v-slot:item.rd="{ item }">
        <div>{{ unixTimestampToFormattedDate(item.rd)}}</div>
      </template>

      <template v-slot:item.pg="{ item }">
        <div>{{ checkItemForZeroOrNull(item.pg)|formatMoney}}</div>
      </template>

      <template v-slot:item.pc="{ item }">
        <div>{{ checkItemForZeroOrNull(item.pc)|formatMoney}}</div>
      </template>
    </v-data-table>
    <div class="text-center pt-2">
      <v-pagination v-model="currentPage" @input="pageClick" :total-visible="20"
        :length="$store.state.purchaseLogs.all.totalPage"></v-pagination>
    </div>
  </div>
</template>
<script>
import "@/assets/css/site.css";

export default {
  components: {
    UserDetailCard: () => import("@/components/Users/UserDetailCard.vue"),
  },
  name: "PurchaseLogsCard",
  props: {
    search: {
      type: String,
    }
  },
  allData:[],
  data() {
    return {
      dates: [
        new Date(Date.now())
          .addDays(-30)
          .toISOString()
          .substr(0, 10),
        new Date(Date.now()).toISOString().substr(0, 10),
      ],
      menu: false,
      pageCount: 0,
      typeSelect: { key: -999, value: "All" },
      pageItemSelect: { id: 25, value: "25" },
      productTypeSelect: { id: -1, value: "Select" },
      loading: false,
      currentPage: 1,
      options: {},
      pagination: {
        sortBy: "name",
      },
      pageItems: [
        { id: 25, value: "25" },
        { id: 50, value: "50" },
        { id: 100, value: "100" },
        { id: 250, value: "250" },
      ],
      productTypeItems: [
        { id:-1, value: "Select" },
        { id: 0, value: "Chip" },
        { id: 1, value: "Gems" },
        { id: 2, value: "PromoCode" },
        { id: 3, value: "Multi" },
        { id: 4, value: "SuperVegasWheel" },
        { id: 5, value: "Daruma" },
        { id: 6, value: "Treasure" },
        { id: 7, value: "CoinFlip" },
        { id: 8, value: "WheelOfFortune" },
        { id: 9, value: "Piggy" },
        { id: 12, value: "Special" },
        { id: 13, value: "Lottery" },
        { id: 14, value: "OneBuyOneFree" },
        { id: 15, value: "ComboChest" },
        { id: 16, value: "SeasonPass" },
        { id: 17, value: "SeasonPassDeluxe" },
        { id: 18, value: "LevelUpBundle" },
      ],
      purchaseAggregationLogsDataTable: [
        { text: "Product Type", value: "pt" },
        { text: "Purchase Count", value: "c"},
        { text: "Total Purchase Chip", value: "tc"},
        { text: "Total Purchase Gems", value: "tg"},
      ],
      purchaseLogsDataTable: [
        { text: "User", value: "u" },
        { text: "Product Id", value: "pi" },
        { text: "Product Type", value: "t" },
        { text: "Register Date", value: "rd" },
        { text: "Purchase Date", value: "pd" },
        { text: "Purchase Level", value: "pl" },
        { text: "Purchase Chip", value: "pc" },
        { text: "Purchase Gems", value: "pg" },
        { text: "County", value: "cn" },
        { text: "Platform", value: "os" },
      ],
    };
  },
  watch: {
    options: {
      handler() {
        if (this.options.sortBy.length > 0) this.initialize();
      },
      deep: true,
    },
  },
  methods: {
    pageClick(a) {
      this.currentPage = a;
      this.initialize();
    },
    async initialize() {
      //this.$refs.menu.save(this.dates);
      this.loading = true;
      await this.$store.dispatch("purchaseLogs/getAll", {
        userId: this.search,
        productType:this.productTypeSelect.id,
        startDate:
          new Date(
            new Date(this.dates[0]).getTime() +
            new Date().getTimezoneOffset() * 60000
          ) / 1000,
        endDate: new Date(this.dates[1]).addDays(1).getTime() / 1000,
        size: this.pageItemSelect.id,
        page: this.currentPage - 1,
      });
  
      // eslint-disable-next-line no-console
      //console.log("Purchase Log :"+this.$store.state.purchaseLogs.aggregation);
      this.loading = false;
    },
    
    unixTimestampToFormattedDate(unixTimestamp){
      if(unixTimestamp===null)
        return null;
      var date = new Date(unixTimestamp * 1000); // Unix zaman damgasını milisaniyeye çevir
      var options = { year: 'numeric', month: 'long', day: 'numeric', hour: 'numeric', minute: 'numeric' };
      var formattedDate = new Intl.DateTimeFormat('tr-TR', options).format(date);
      return formattedDate;
    },
    checkItemForZeroOrNull(item){
      if(item===null ||item===0)
        return "";
      return item;
    },
  },


  async mounted() {
    await this.initialize();
  },

};

</script>

<style>
.card-p {
  width: 142px;
  height: 155px;
  background-size: 142px 155px;
  background-repeat: no-repeat;
  border-radius: 9.5px;
  margin-right: 12px;
  margin-top: 2px;
  margin-bottom: 2px;
}

.lime-card {
  background-image: url(https://bundle.slotbase.net/assets/img/green.png);
}

.green-card {
  background-image: url(https://bundle.slotbase.net/assets/img/green.png);
}

.red-card {
  background-image: url(https://bundle.slotbase.net/assets/img/red.png);
}

.orange-card {
  background-image: url(https://bundle.slotbase.net/assets/img/orange.png);
}

.v-tooltip__content {
  opacity: 1 !important;
}

.jv-container {
  background: #0000005c !important;
}

.jsoneditor-field {
  color: #b7b3c3 !important;
}

a.jsoneditor-value.jsoneditor-url,
div.jsoneditor-value.jsoneditor-url {
  color: #00cfff !important;
}

div.jsoneditor-value.jsoneditor-array,
div.jsoneditor-value.jsoneditor-object {
  color: #b7abab !important;
}

.ace-jsoneditor .ace_variable,
.ace-jsoneditor .ace_gutter-cell {
  color: #fff !important;
}

div.jsoneditor-value.jsoneditor-string {
  color: #00cfff;
}

.ace-jsoneditor .ace_scroller,
.ace-jsoneditor .ace_gutter {
  background: #000 !important;
}
</style>
